.all-audio-container {
  padding: 20px;
  background-color: #ffffff;
  p{
    font-size: 1.4em;
    font-weight: bolder;
    text-align: left;
    margin-left: 4rem;
    margin-top: 2rem;
    color: black;
  }
}

.filter-container {
  top:-2rem;
  position: relative;
  display: flex;
  gap: 10px;
  
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 6rem;
  margin-right: 6rem;
}

.category-button {
  padding: 10px 20px;
  border: 1px solid #0e0d0d;
  border-radius: 30px;
  background-color: white;
  color:black;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-button.selected {
  background-color: #6637ED;
  color: white;
}

.category-button:hover {
  background-color: #6637ED;
  color:white;
}

.audio-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;
  height: auto;
}

.audio-box {
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s;
  height: 100%;
  
  justify-content: center;
  align-items: center;
  background-size: cover;
    background-position: center;
}
.audio-boxF {
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s;
  height: 100%;
  height: 120%;
  
  justify-content: center;
  align-items: center;
  background-size: cover;
    background-position: center;
}
.audio-bannerFilter{
  width: 90%;
  height: 120%;
  object-fit: cover;
  position: relative;
  top: -2.3rem;
  bottom: 0;
  left: 0;
}

.audio-box:hover {
  transform: translateY(-5px);
}

.audio-banner {
  width: 135%;
  max-height: 300px;
  object-fit: cover;
  display: block;
}

h3 {
  margin: 10px 0;
  font-size: 1em;
}

p {
  color: #666;
  font-size: 0.9em;
}

.popular {
  margin-top: 30px;
}

.swiper-container {
  padding: 20px 4rem;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}




.slide-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
}

.swiper-container {
  height: 300px;
  position: relative;
  top:-2rem;
}
.audio-duration {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(31, 31, 31, 0.30);;
  color: white;
  padding: 5px 20px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}

@media (max-width: 768px) {
  .audio-grid {
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }

  .category-button {
    padding: 8px 15px;
    font-size: 0.9em;
  }

  .audio-box {
    padding: 8px;
  }

  .audio-banner {
    max-height: 190px;
  }
}

@media (max-width: 480px) {
  .audio-grid {
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .category-button {
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .audio-box {
    padding: 5px;
  }
  .audio-boxF{
    height:120%;
  }

  .audio-banner {
    max-height: 190px;
  }
  .audio-bannerFilter{
    height: 126%;
    top:-2.9rem;
    bottom:2rem;
    
  }
  .popular p{
    margin-left: 0 !important;
  }
}
.search-bar-container {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Allow the search bar to grow to fill available space */
  max-width: 270px; /* Limit width to maintain responsiveness */
  gap:1rem;
}

.search-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%; /* Take up full width available */
  font-size: 0.9em;
}

.search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #6637ED;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  position:relative;
  left:-2rem;
}

.search-button:hover {
  background-color: #5a2dd6;
}

@media (max-width: 768px) {
  .search-bar-container {
    max-width: 100%; /* Allow the search bar to take full width on smaller screens */
  }
  
  .search-button {
    padding: 10px 15px; /* Adjust button padding for smaller screens */
  }
  
  .search-input {
    font-size: 0.8em; /* Adjust font size for smaller screens */
  }
}
  
    
  
  
@media(max-width:590px)
{
  .filter-container{
    margin-left: 0;
    margin-right: 0;
    padding-left: 0rem;
    padding-right: 2rem;
  }
  .swiper-container {
    height: 300px;
    position: relative;
    top:-5rem;
  }
  .all-audio-container p {
    margin-left: 0;
  }
    
}
@media(max-width:375px)
{
  .audio-boxF{
    height:110%;
  }

  .audio-banner {
    max-height: 190px;
  }
  .audio-bannerFilter{
    height: 100%;
    top:-0.0rem;
    bottom:2rem;
    
  }
  .popular p{
    margin-left: 0px;
  }
}
@media (max-width:1440px) {
.swiper-containerSearched{
  top:2rem;
  width:90%;
  height:20rem;
  .audio-banner{
    width:120%;
    top:4.6rem;


  }
  .audio-box{
    height: 170%;
    padding: 2rem;
  }
}
  }
  @media(min-width:500px) and (max-width:800px){
  .swiper-containerSearched{
    top:2rem;
    width:90%;
    
    .audio-box{
      height:100%;
      padding: 2rem;

    }
    .audio-banner{
      width:120%;
      height:120%;
      top:0.5rem;
  
    }
  }
}

@media(min-width:376px) and (max-width:500px){
  .swiper-containerSearched{
    top:2rem;
    width:90%;
    
    .audio-box{
      height:100%;

    }
    .audio-banner{
      width:120%;
      height:120%;
      top:0.5rem;
  
    }
  }
}

@media(min-width:300px) and (max-width:375px){
  .swiper-containerSearched{
    top:2rem;
    width:90%;
    
    .audio-box{
      height:100%;
      padding:0.3rem;

    }
    .audio-banner{
      width:120%;
      height:120%;
      top:0.5rem;
  
    }
  }
}

