.resource-details {
  padding: 0;
  margin: 0 auto;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.share {
  font-size: 1.6rem;
}
.ResourceText{
  color:white;
}

.hero-section {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  padding: 2rem;
  color: #fff;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  z-index: 70;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.hero-textResource {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}
.ResourceName{
  font-family: 'Montserrat', sans-serif;
}

.hero-text p {
  position: relative;
}

.details-section {
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  border-radius: 3rem;
  gap: 20px;
  padding: 2rem;
  flex-wrap: wrap;
  position: relative;
  top: -2.2rem;
  background-color: #FFFFFF;
  z-index: 80;
  width: 100%;
}

.details-section .summary {
  margin: 0 !important;
}

.summary p {
  text-align: left;
  color: #555;
  font-size: 19px;
}

.like-section {
  margin-top: 1rem;
  text-align: left;
}

.like-button {
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  background-color: transparent;
}

.like-button:hover {
  background-color: transparent;
  color: #FFFFFF;
}

.pdf-section {
  margin: 2rem 0;
  padding: 3rem;
  border-radius: 10px;
  border: 0.5px solid #d3d0d0;
  background-color: #fff;
  width: 50%;
  max-height: 600px;
}

.side-boxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
  justify-content: space-between;
  margin: 2rem 0;
}

.box {
  padding: 2rem;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.box h4 {
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Gilroy-Bold';
  font-size: 1.5rem;
}

.box p {
  color: #555;
  font-size: 1rem;
  font-family: 'Europa Grotesk SH', sans-serif;
}

.popular {
  margin-top: 2rem;
  text-align: left;
 
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero-section {
      flex-direction: column;
      align-items: center;
  }

  .details-section {
      flex-direction: column;
      align-items: center;
  }

  .pdf-section,
  .side-boxes {
      width: 100%;
  }

  .side-boxes {
      flex-direction: column;
  }
}
