body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard {
  padding: 20px;
  text-align: left;
  margin: 5rem;
}

.dashboard h1 {
  text-align: left;
  margin-bottom: 20px;
  font-size: larger;
}

.dashboard-boxes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
}

.dashboard-box {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 20px;
  margin: 10px;
  background-color: white;
  border-radius: 8px;
  border: 0.5px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.dashboard-box:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.dashboard-box h2 {
  width: 40%;
  margin-bottom: 10px;
  font-size: xx-large;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.dashboard-box p {
  margin: 0;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 600px) {
  .dashboard {
    margin: 1rem;
    padding: 10px;
  }

  .dashboard-boxes {
    flex-direction: column;
    gap: 1rem;
  }

  .dashboard-box {
    flex-direction: column;
    padding: 10px;
    margin: 5px;
  }

  .dashboard-box h2 {
    width: 100%;
    font-size: large;
  }
}
