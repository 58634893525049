

.App {
  text-align: center;
  width: 100%;
    min-height: 100vh;
    background-color:#F1F1F1;
    font-family: 'Gilroy-Medium', sans-serif;

    overflow-x: hidden;
    

}

