.audio-book-detail {
  max-width: 1500px;
  margin: 0 auto;
  background-color: #FFFFFF;
}
.share{
  font-size: 1.6rem;
}

.hero-section {
  position: relative;
  height: 350px;
  display: flex;
  align-items: center;
  padding: 2rem;
  color: #fff;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  z-index: 70;
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  overflow: hidden;
}

.hero-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.banner-img {
  height: 80%;
  width: 12rem;
  margin-right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 90;
}

.hero-text {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.hero-text p {
  position: relative;
}

.details-section {
  display: flex;
  
  justify-content: space-between;
  padding-top: 5rem;
  border-radius: 3rem;
  gap: 20px;
  padding: 2rem;
  flex-wrap: wrap;
  position: relative;
  top:-2.2rem;
  background-color: #FFFFFF;
  z-index: 80;;

}
.details-section .summary{
  margin: 0 !important;
  width: 50%;
  
}
.details-section .column{
  width:50%;
}
.details-section .summary, .details-section .skills {
 
  padding: 20px;
  border: 1px solid #d3d0d0;
  border-radius: 10px;
  background-color: #fff;
  margin: 1rem 0;
}

.details-section h2 {
  margin-bottom: 10px;
  text-align: left;
}

.details-section .skills ul {
  list-style-type: none;
  padding-left: 20px;
}

.summary p {
  text-align: left;
  color:#555;
  font-size: 19px;
}

.audio-player {
  flex-grow: 1;
}

.like-section {
  margin-top: 1rem;
  text-align: left;
}

.like-button {
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  background-color: transparent;
}

.like-button:hover {
  background-color: transparent;
  color: #FFFFFF;
}

.audio-control-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 1100;
  background-color: #FFFFFF;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.banner-img-small {
  width: 50px;
  margin-right: 1rem;
}
@font-face {
  font-family: 'Europa Grotesk SH';
  src: url('../../../public/fonts/Europa_Gro_Nr_2_SH_Bold_Cond.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
.AudioName {
  font-family: 'Europa Grotesk SH', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 2.5rem;
}
.AudioText{
  display: flex;
  gap: 1rem;
  font-size: 1.7rem;
}
@media (max-width: 767px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    margin-top: 20px;
    left: -2.7rem;
    position: relative;
  }

  .details-section {
    flex-direction: column;
    padding: 1rem;
  }

  .details-section .summary, .details-section .skills,.InviteImg {
    flex: 1 1 100%;
    margin: 1rem 0;
    width:100%;
  }
}

.popular p {
  font-size: 1.4em;
  font-weight: bolder;
  text-align: left;
  margin-left: 4rem;
  margin-top: 2rem;
  color: black;
}
.AudioText {
  color: white;
  text-align: left;
  font-size: 1.4rem;
 
}

@media (min-width: 768px) {
  .hero-section {
    gap: 5rem;
    padding: 2rem 10rem;
  }

  .details-section {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .details-section .summary, .details-section .skills {
    flex: 1;
  }
}

@media (max-width: 652px) {
  .hero-text {
    font-size: small;
    width: 15rem;
    padding: 1rem;
  }
  .share{
    font-size: 1.2rem;
  }
  .hero-text h1 {
    font-size: medium;
    width: 9rem;
  }

  .banner-img {
    width: 10rem;
  }
  .AudioText {
    font-size: 0.8rem;
  }
}
@media(max-width:767px)
{
  .column{
    width:100%!important;
  }
  .InviteImg{
    width:20rem;
    width:100%;
  }
  .like-button{
    font-size: 1rem;
  }
}
@media(min-width:1000px) and  (max-width:1073px){
  .details-section .summary{
    width: 10%;
  }
  .details-section .column{
    width:15rem;
  }
}
@media(max-width:375px) {
  .popular p{
    margin-left: 0px;
  }
  
}
