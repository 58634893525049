/* Navbar.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .navbar {
    background-color: #333;
    color: #fff;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .nav{
    display: flex;
    gap:1rem;
    justify-content: center;
    align-items: center;
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .logo {
    margin-left: 1rem;
    font-size: 1.5em;
    font-weight: bold;
    width:9rem;
  }
  
  .menu {
    display: flex;
    gap: 20px;
  }
  
  .menu a {
    color: #fff;
    text-decoration: none;
    padding: 8px 12px;
    transition: background-color 0.3s;
  }
  
  .menu a:hover {
    background-color: #575757;
    border-radius: 5px;
  }
  
  .instructor-button button {
    padding: 8px 12px;
    background-color: #6637ED;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .instructor-button button:hover {
    background-color: #fff;
    color:#6637ED;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
    transition: all 0.3s;
  }
  
  .bar.open:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }
  
  .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .bar.open:nth-child(3) {
    transform: translateY(-14px) rotate(-45deg);
  }
  
  @media (max-width: 768px) {
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: #333;
      z-index: 1;

    }
  
    .menu.open {
      display: flex;
    }
  
    .hamburger {
      display: flex;
      height: 2rem;
    }
  
    .instructor-button {
      
      position: relative;
    }
    .logo{
      width: 5rem;
    }
    .navbar{
      padding :15px 0;
    }
    .hamburger{
      left:-1rem;
      position: relative;
    }
  }
  
