/* Basic resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.footer {
  background-color: #FFF;
  color: #5E5E5E;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.footer-content {
  width: 100%;
  display: flex;
 
  align-items: center;
  gap: 3rem;
  margin-left: 5rem;
  margin-right: 5rem;
}
.subscribe{
  display: flex;
  flex-direction: column;
}
.footer-subscribe {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.footer-input {
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 80%;
  max-width: 300px;
}

.footer-button {
  padding: 10px 20px;
  border: none;
  top:-0.3rem;
  border-radius: 5px;
  background-color: #6637ED;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.footer-social-media {
  display: flex;
  gap: 1rem;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.footer-social-link {
  color: #5E5E5E;
  font-size: 1.5rem;
  text-decoration: none;
}

.footer-social-link:hover {
  color: #6637ED;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5rem;
  margin-bottom: 20px;
  width: 70%;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-label {
  font-weight: 800;
  margin-bottom: 10px;
  color: #5E5E5E;
  font-size: 20px;
}

.footer-link {
  color: #5E5E5E;
  text-decoration: none;
  margin: 5px 0;
  font-weight: 700;
  font-size: 13px;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-rights {
  color: #aaa;
  text-align: center;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .footer-subscribe {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-input {
    margin-bottom: 10px;
    width: 80%;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    align-items: center;
  }
}
