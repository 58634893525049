.admin-audiobooks {
  padding: 20px;
}

.table-container {
  overflow-x: auto;
}

.content-table {
  width: 100%;
  border-collapse: collapse;
}

.content-table th,
.content-table td {
  border: 1px solid #000000;
  padding: 8px;
  text-align: center;
}

.content-table th {
  background-color: #D9D9D9;
}

.edit-button, .delete-button {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 4px;
}

.edit-button {
  background-color: #36D1DC;
}

.delete-button {
  background-color: #FF6B6B;
}

.edit-button:hover, .delete-button:hover {
  opacity: 0.8;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .admin-audiobooks {
    padding: 10px;
  }

  .content-table th, .content-table td {
    padding: 6px;
    font-size: 14px;
  }

  .edit-button, .delete-button {
    padding: 6px 10px;
  }
}

@media (max-width: 480px) {
  .admin-audiobooks {
    padding: 5px;
  }

  .content-table th, .content-table td {
    padding: 4px;
    font-size: 12px;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .content-table th {
    font-size: 14px;
    display: block;
  }

  .content-table td {
    font-size: 12px;
    display: block;
    text-align: left;
    border: none;
    border-bottom: 1px solid #000000;
  }

  .edit-button, .delete-button {
    padding: 4px 8px;
    font-size: 12px;
  }
}
