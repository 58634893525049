.all-resources-container {
  padding: 20px;
  background-color: #ffffff;
}

.all-resources-container p {
  font-size: 1.4em;
  font-weight: bolder;
  text-align: left;
  margin-left: 4rem;
  margin-top: 2rem;
  color: black;
}

.search-bar-container {
  /* Add styles for search-bar-container if needed */
  display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 270px;
    gap: 1rem;

}

.popular p {
  color: #000;
  font-size: 1.4em;
  font-weight: bolder;
  margin-left: 4rem;
  margin-top: 2rem;
  text-align: left;
}

.resource-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  top: 2rem;
  z-index: 2000;
  width:100%;

}

.resource-banner {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  
}

.resource-details {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resource-details h3 {
  font-weight: 300;
}

.view-button {
  background-color: #1B4965;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.youtube {
  margin-top: 2rem;
}

.youtube h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.youtube-thumbnail {
  width: 70%;
  height: auto;
  border-radius: 20px;
  cursor: pointer;
}
.swiper-containerYT{
  margin-top: 4rem;

}
.Resource-name {
  position: absolute;
  
 
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  left:2rem;
  top:4rem;
}
.Resource-name p{
  color:white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
}
.swiper-slideYT {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.video-player{
  margin-top:3rem;
}
@media (max-width: 375px) {
  .popular p {
    margin-left: 0;
  }

  .resource-box {
    height: auto;
  }

  .resource-banner {
    height: 150px; /* Adjust for smaller screens */
  }
  .container{
    gap:2rem;
    
  }
  .search-bar-container{
    position: relative;
    top:-0.5rem;
  }
}
@media (min-width: 1200px)
{
  .youtube-thumbnail {
    border-radius: 50px;
  }
}