.popular-audio-container {
  padding: 20px;
  margin-top: 3rem;
}

.swiper-container {
  width: 100%;
  height: 300px; /* Set initial height for the Swiper container */
}

.popularSlide {
  width: 100%; /* Full width for mobile view */
  height: auto; /* Auto height to adjust with content */
  flex-shrink: 0; /* Prevent shrinking in smaller containers */
}

.audiobook-slide {
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
  width: 100%; /* Full width for mobile view */
}

.slide-imageP {
  width: 100%;
  height: 12rem;
  object-fit: cover; /* Ensures the image covers the entire slide without stretching */
  border-radius: 10px; /* Matches the border-radius of the .audiobook-slide */
}

.audiobook-content {
  padding: 10px; /* Reduced padding for content */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for content */
  color: #ffffff;
  width: 100%;
}

.audiobook-content h3 {
  margin-bottom: 5px; /* Reduced margin */
  font-size: 1rem; /* Adjusted font size */
}

.audiobook-content p {
  font-size: 0.8rem; /* Adjusted font size */
  line-height: 1.2;
  margin-bottom: 0;
}

/* Media Queries for Responsive Design */

/* Adjustments for small devices */
@media (max-width: 767px) {
  .swiper-container {
    height: 200px; /* Decreased height for mobile view */
  }
  
  .audiobook-slide {
    height: 10rem; /* Decreased height for mobile view */
  }

  .slide-imageP {
    height: 10rem; /* Decreased height for mobile view */
  }

  .audiobook-content {
    padding: 8px; /* Further reduced padding for mobile view */
  }

  .audiobook-content h3 {
    font-size: 0.9rem; /* Slightly smaller font size for mobile view */
  }

  .audiobook-content p {
    font-size: 0.7rem; /* Slightly smaller font size for mobile view */
  }
}

/* Adjustments for medium devices */
@media (min-width: 768px) and (max-width: 1023px) {
  .swiper-container {
    height: 350px; /* Increased height for medium screens */
  }
  
  .audiobook-slide {
    height: 12rem; /* Adjusted height for medium screens */
  }

  .slide-imageP {
    height: 14rem; /* Adjusted height for medium screens */
  }
}

/* Adjustments for large devices */
@media (min-width: 1024px) {
  .swiper-container {
    height: 450px; /* Further increased height for extra-large screens */
  }
  
  .audiobook-slide {
    height: 110%; /* Increased height for large screens */
  }

  .slide-imageP {
    height: 14rem; /* Increased height for large screens */
  }
}
@media (max-width: 375px) {
  .popular-audio-container{
    margin-top:5rem;
  }
  .audiobook-slide{
    height:100%;
  }
 
}
@media (max-width: 320px) {
  .popular-audio-container{
    margin-top:5rem;
  }
  .audiobook-slide{
    height:90%;
  }
 
}
@media (max-width: 425px) and (min-width:376px){
  .popular-audio-container{
    margin-top:6rem;
  }
  .audiobook-slide{
    height:135%;
  }
  .slide-imageP{
    height:17rem

  }
 
}
