.pending-contents {
   
    margin: 0 auto;
    padding: 20px;
    
    border-radius: 8px;
    background-color: #f0f0f0;
  }
  
  h1 {
    
    margin-bottom: 20px;
  }
  
  .table-container {
    overflow-x: auto;
   
  }
  
  .content-table {
    width: 90%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
  
  .content-table th,
  .content-table td {
    padding: 12px;
    text-align: center;
     /* Adds bottom border to each cell */
    border-right: 1px solid black; /* Adds right border to each cell */
    
  }
  .approve-button{
    background-color: #009A22;
    height:2rem;
    top:40%;
    position: relative;
  }
  .decline-button{
    background-color: #ED4237;
    height:2rem;
    top:40%;
    position: relative;

  }
  .content-table th {
    background-color: #F1F1F1;
  }
  
  .content-table tbody tr:nth-child(even) {
    background-color: #F1F1F1;
    height: 2rem;
    border:1px solid black;
  }
  .content-table tbody tr:nth-child(odd) {
    background-color: #F1F1F1;
    height: 2rem;
  }
  
  .content-table tbody tr:hover {
    background-color: #e9f5fb;
  }
  
  .details-button
  {
    padding: 8px 16px;
    border: none;
    background-color: #6637ed;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .details-button:hover,
  .approve-button:hover {
    background-color: #512dc3;
  }
  