.popular-resources-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .resource-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .slide-imageR {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  