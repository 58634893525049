/* toastStyles.css */
.Toastify__toast--success {
    background-color: #28a745 !important; /* Green background for success */
    color: white !important;
  }
  
  .Toastify__toast--error {
    background-color: #dc3545 !important; /* Red background for error */
    color: white !important;
  }
  
  .Toastify__toast--info {
    background-color: #17a2b8 !important; /* Blue background for info */
    color: white !important;
  }
  